/*
 * @Description:
 * @Autor: lichao
 * @Date: 2021-12-08 14:28:31
 * @LastEditors: lichao
 * @LastEditTime: 2022-01-26 15:24:51
 */

import { lazyLoad } from "./index";

// component
const Home = lazyLoad(() => import("../page/Home"));
const Course = lazyLoad(() => import("../page/Course"));
const CourseHome = lazyLoad(() => import("../page/Course/CourseHome"));
//专题和小镇投资说专题列表
const CourseSpecialList=lazyLoad(()=>import("../page/Course/CourseSpecialList"));
const CourseSpecial = lazyLoad(() => import("../page/Course/CourseSpecial"));
const CourseArticle = lazyLoad(() => import("../page/Course/CourseArticle"));
const CourseVideo = lazyLoad(() => import("../page/Course/CourseVideo"));
const CourseList = lazyLoad(() => import("../page/Course/CourseList"));
const InvBase = lazyLoad(() => import("../page/InvBase"));
const News = lazyLoad(() => import("../page/News"));
const NewsHome = lazyLoad(() => import("../page/News/NewsHome"));
const NewsArticle = lazyLoad(() => import("../page/News/NewsArticle"));
const ReadHome = lazyLoad(() => import("../page/Read/ReadHome"));
const Read = lazyLoad(() => import("../page/Read"));
const Interact = lazyLoad(() => import("../page/Interact"));
const InteractHome = lazyLoad(() => import("../page/Interact/InteractHome"));
const Special = lazyLoad(() => import("../page/Read/ReadSpecial"));
const ReadArticle = lazyLoad(() => import("../page/Read/ReadArticle"));
const NotFound = lazyLoad(() => import("../page/NotFound"));
let PrivacyPolicy = lazyLoad(() => import("../page/PrivacyPolicy"));
// routes
const routes = [
  {
    path: "/home",
    component: Home,
    exact: false,
    children: [],
    title: "北京基金小镇投教基地",
  },
  {
    path: "/course",
    component: Course,
    exact: false,
    title: "投资课堂",
    children: [
      // 课程首页
      {
        path: "/course",
        component: CourseHome,
        title: "投资课堂",
        exact: true,
        children: [],
      },
        //专题和小镇投资说合集列表  Collection合集  为nc显示special专题列表 为1 显示小镇投资说合集
      {
        path: "/course/speciallist/:cid",
        component: CourseSpecialList,
        title: "投资课堂",
        exact: true,
        meta: [
            { name: "投资课堂", path: "/course" },
            { name: "专题列表" },
            {name:"小镇投资说"}],
        children: [],
      },
      {
        path: "/course/special/:id",
        component: CourseSpecial,
        title: "投资课堂",
        exact: true,
        meta: [
            { name: "投资课堂", path: "/course" },
            { name: "专题" },
            { name: "小镇投资说" },
        ],
        //meta: [{ name: "投资课堂", path: "/course" }],
        children: [],
      },
      // 课程详情
      {
        path: "/course/article/:id",
        component: CourseArticle,
        exact: true,
        title: "投资课堂",
        meta: [{ name: "投资课堂", path: "/course" }],
        children: [],
      },
      // 课程视频专题播放页
      {
        path: "/course/video/:id/:sid",
        component: CourseVideo,
        exact: true,
        title: "投资课堂",

        meta: [
          { name: "投资课堂", path: "/course" },
          { name: "专题", path: "/course/special" },
          { name: "小镇投资说", path: "/course/special" },
        ],
        children: [],
      },
      // 课程列表
      {
        path: "/course/list",
        component: CourseList,
        exact: true,
        title: "投资课堂",
        meta: [{ name: "投资课堂", path: "/course" }],
      },
      {
        component: NotFound,
      },
    ],
  },
  {
    path: "/read",
    component: Read,
    exact: false,
    title: "投资必读",

    children: [
      {
        path: "/read",
        component: ReadHome,
        title: "投资必读",
        exact: true,
        children: [],
      },

      {
        path: "/read/special/:id",
        component: Special,
        title: "投资必读",
        exact: true,
        meta: [
          { name: "投资必读", path: "/read" },
          { name: "专题" }
          // { name: "专题", path: "/read/special" }
        ],
        children: [],
      },
      {
        path: "/read/article/:id",
        component: ReadArticle,
        title: "投资必读",
        exact: true,
        meta: [
          { name: "投资必读", path: "/read" },
          { name: "专题", path: "/read/special" },
        ],
      },
      {
        component: NotFound,
      },
    ],
  },
  {
    path: "/news",
    component: News,
    exact: false,
    title: "投教动态",
    children: [
      // 课程首页
      {
        path: "/news",
        component: NewsHome,
        title: "投教动态",
        exact: true,
        children: [],
      },
      // 课程专题列表
      {
        path: "/news/article/:id",
        component: NewsArticle,
        title: "投教动态",
        exact: true,
        meta: [{ name: "投教动态", path: "/news" }],
        children: [],
      },
      {
        component: NotFound,
      },
    ],
  },
  {
    path: "/interact",
    title: "互动体验",
    component: Interact,
    exact: false,
    children: [
      {
        title: "互动体验",
        path: "/interact",
        component: InteractHome,
        exact: true,
        children: [],
      },
      {
        component: NotFound,
      },
    ],
  },
  {
    title: "相约基地",
    path: "/invbase",
    component: InvBase,
    exact: false,
    children: [],
  },
  {
    path: "/privacyPolicy",
    component: PrivacyPolicy,
    title: "隐私政策-北京基金小镇投教基地",
    exact: false,
  },
  {
    path: "/",
    redirect: "/home",
    exact: true,
    title: "北京基金小镇投教基地",
  },
  {
    title: "404",
    path: "/404",
    component: NotFound,
  },
  {
    component: NotFound,
  },
];

export default routes;
