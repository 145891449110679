/*
 * @Description:
 * @Autor: lichao
 * @Date: 2021-12-08 13:43:46
 * @LastEditors: lichao
 * @LastEditTime: 2021-12-27 09:19:19
 */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.less";
import App from "./App";

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
