import { NavLink } from "react-router-dom";

import "./index.less";

const MenuList = [
  { name: "首页", path: "/home" },
  { name: "投资课堂", path: "/course" },
  { name: "投资必读", path: "/read" },
  { name: "投教动态", path: "/news" },
  { name: "互动体验", path: "/interact" },
  { name: "相约基地", path: "/invbase" },
];

const getMenuList = (MenuList) =>
  MenuList.map((m, i) => (
    <li key={i}>
      <NavLink to={m.path} activeClassName="menu-list-active">
        {m.name}
      </NavLink>
    </li>
  ));

const Menu = (props) => {
  return (
    <div className="menu">
      <div className="safetyZone menu-container">
        <div className="menu-logo">
          <img src="/image/investment_education_logo.png" alt="" />
        </div>
        <ul className="menu-list">{getMenuList(MenuList)}</ul>
      </div>
    </div>
  );
};

export default Menu;
