/*
 * @Description: router
 * @Autor: lichao
 * @Date: 2021-12-08 14:22:45
 * @LastEditors: lichao
 * @LastEditTime: 2022-01-12 14:55:08
 */
import { Suspense, lazy } from "react";
import { Route, Redirect } from "react-router-dom";
import { Spin } from "antd";


/**
 * route each
 * @param {Array} routes
 * @returns component
 */
export const renderRoutes = (routes) => {
  return routes.map((route, index) => {
    const { children, path, exact = false, meta = [], title } = route;
    return route.component ? (
      <Route
        path={path}
        exact={exact}
        key={`${path}-${index}`}
        render={(props) => {
          window.scrollTo(0, 0);
          setTitle(title);
          return <route.component {...props} routes={children} meta={meta} />;
        }}
      />
    ) : (
      <Route
        path={path}
        exact={exact}
        key={`${path}-${index}`}
        render={(props) => {
          window.scrollTo(0, 0);
          setTitle(title);
          return <Redirect to={route.redirect} from="*" />;
        }}
      ></Route>
    );
  });
};

/**
 * route lazy
 * @param {Oject} component
 * @returns component
 */
export const lazyLoad = (component) => {
  const Component = lazy(component);
  return (props) => (
    <Suspense
      fallback={
        <div className="spin-loading">
          <Spin size="large" tip="数据加载中" />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export const setTitle = (title) => {
  document.title = title;
};

