/*
 * @Description:
 * @Autor: lichao
 * @Date: 2021-12-08 13:43:46
 * @LastEditors: lichao
 * @LastEditTime: 2021-12-27 09:19:38
 */
import { useLocation } from 'react-router'
import {useEffect} from "react";
import { renderRoutes } from "./router/index";
import { Switch } from "react-router-dom";
import SiteMapFooter from "./components/SiteMapFooter";
import routes from "./router/routes";
import Menu from "./components/Menu";
import "./App.css";

function App(props) {
  useEffect(()=> {
      //友盟统计数据，单页面应用需要监听路由跳转
       const hm=document.createElement('script');
       hm.src="https://s4.cnzz.com/z_stat.php?id=1280737802&web_id=1280737802";
       const s=document.getElementsByTagName('script')[0];
       s.parentNode.insertBefore(hm,s)
  }, [useLocation()])

  return (
    <div className="App">
      <Menu />
      <Switch>{renderRoutes(routes)}</Switch>
      <SiteMapFooter />
    </div>
  );
}

export default App;
