/*
 * @Description:
 * @Autor: lichao
 * @Date: 2021-12-14 16:22:05
 * @LastEditors: lichao
 * @LastEditTime: 2022-01-25 16:56:33
 */
import "./index.less";
import {Link} from "react-router-dom";
const SiteMapFooter = () => {
  return (
    <div>
      <div
        className="siteMapFooter"
        style={{ background: "url(/image/SiteMapFooter.png) no-repeat top" }}
      >
        <div className="footer-content w1200">
          <div className="content-top">
            <img src="/image/footer-brand.png" alt="" />
            <ul>
              <li>
                <img src="/image/tel.png" alt="" />
                联系电话：010-61336961
              </li>
              <li>
                <img src="/image/email.png" alt="" />
                联系邮箱：news@bjfundtown.com
              </li>
              <li>
                <img src="/image/address.png" alt="" />
                基地地址：北京基金小镇国际会议中心
              </li>
            </ul>
            <div className="gongzhonghao">
              <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/public/static/images/gzhh_code.jpg" alt="" />
              <span>扫一扫关注公众号</span>
            </div>
            <div className="gongzhonghao">
              <img src="https://fund-town-1304488676.cos.ap-beijing.myqcloud.com/public/static/images/tjapp_download.png" alt="" />
              <span>投教基地APP</span>
            </div>
          </div>
          <div className="content-bottom">
            <img src="/image/footer-logo.png" alt="" />
            <ul>
                {/*<li>*/}
                {/*    <Link to="/privacyPolicy">隐私政策</Link>*/}
                {/*</li>*/}
              <li>
                <a href="http://www.csrc.gov.cn/" target="_blank" rel="noreferrer">中国证券监督管理委员会</a>
              </li>
              <li>
                <a href="https://www.amac.org.cn/" target="_blank" rel="noreferrer">中国证券投资基金业协会</a>
              </li>
              <li>
                <a href="https://www.investor.org.cn/" target="_blank" rel="noreferrer">中国投资者网</a>
              </li>
              {/* <li>
                <a href="http://www.xinhuanet.com/finance/tjjd/" target="_blank" rel="noreferrer">新华网投资者教育基地</a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div
        style={{
          background: "#1A1D25",
          color: "#Fff",
          lineHeight: "50px",
          height: "50px",
          textAlign: "center",
        }}
      >
        京ICP备16008758号-1
      </div>
    </div>
  );
};

export default SiteMapFooter;
